<template>
  <el-card shadow="never">
    <el-form inline>
      <el-form-item>
        <el-input v-model="code" placeholder="内部归还单号" clearable style="width: 210px"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="forceClose" :loading="loading">关单</el-button>
        <el-button type="primary" @click="reopen" :loading="loading">打开</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
export default {
  name: "ForceClose",
  data() {
    return {
      code: '',
      loading: false
    }
  },
  methods: {
    forceClose() {
      if (!this.code) {
        return this.$message.error('内部归还单号不能为空！');
      }

      this.$confirm('确定关单吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.loading = true;
        this.$axios.get('returnOrder/forceClose', {
          params: {
            code: this.code
          }
        }).then(response => {
          this.loading = false;
          let res = response.data;
          if (res.code !== 0) {
            return this.$message.error(res.message);
          }

          this.code = ''
          return this.$message.success('操作成功');

        }, error => {
          this.loading = false;
          return this.$message.error('操作失败，' + error.message);
        });
      })

    },
    reopen() {
      if (!this.code) {
        return this.$message.error('内部归还单号不能为空！');
      }
      this.$confirm('确定打开吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.loading = true;
        this.$axios.get('returnOrder/reopen', {
          params: {
            code: this.code
          }
        }).then(response => {
          this.loading = false;
          let res = response.data;
          if (res.code !== 0) {
            return this.$message.error(res.message);
          }

          this.code = ''
          return this.$message.success('操作成功');

        }, error => {
          this.loading = false;
          return this.$message.error('操作失败，' + error.message);
        });
      })
    }
  }
}
</script>

<style scoped>

</style>